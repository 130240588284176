import React from 'react'
import Helmet from 'react-helmet'
import Img from 'gatsby-image'
import { Link, graphql } from 'gatsby'

import Layout from 'src/components/layout'
import Row from 'src/components/row'

const Page = ({ data: { site, imgHeader, img1, img2, img3 } }) => (
  <Layout>
    <div className="content">
      <Helmet>
        <title>
          Plumbing, Residential &amp; Commercial, Kitchen &amp; Bath -{' '}
          {site.siteMetadata.title}
        </title>
      </Helmet>
      <p className="center">
        <Img
          style={{
            maxWidth: imgHeader.childImageSharp.original.maxWidth,
          }}
          fluid={imgHeader.childImageSharp.fluid}
        />
      </p>

      <hr />

      <h2>Plumbing Division</h2>
      <Row columns={2}>
        <div>
          <h3>Residential Plumbing</h3>
          <u>Service and Repair</u>
          <p>
            Solutions to all plumbing problems: plugged drains, mop sinks,
            toilets, sinks, urinals, basins, floor drains, leaks, drain rooter,
            toilet plugged, drips. We also repair water and sump pumps, and work
            with both sewer-connected and septic tank systems.
          </p>
          <u>New Installations and Renovations</u>
          <p>
            Laundry rooms, bathrooms, powder rooms
            Bathtub/showers/basins/toilets Kitchen sinks, dishwashers,
            garburetors, instant hot Refrigerator water lines bathroom and
            kitchen remodelling New washrooms Automatic Lawn sprinklers and
            garden hose bibs.
          </p>
        </div>
        <div>
          <h3>Commercial Service</h3>
          <p>
            New Construction/Installation, Restoration, Service, Repair, and
            Renovations. Installation of new fixtures, commercial kitchens.
            General repair and renovation work including:
          </p>
          <ul>
            <li>
              <Link to="/backflow-preventer-testing/">
                backflow preventer installation and testing
              </Link>
            </li>
            <li>public washrooms</li>
            <li>restaurant and bakery kitchens</li>
            <li>grease trap installations</li>
            <li>industrial trench drains</li>
            <li>commercial grade dishwasher installations</li>
            <li>urinals, toilets, basins</li>
            <li>motion sensing faucets and flush valves</li>
            <li>process piping: air compressor air lines</li>
            <li>sprinkler systems</li>
          </ul>
        </div>
      </Row>

      <Row columns="3">
        <p>
          <Img
            style={{
              maxWidth: 235,
            }}
            fluid={img1.childImageSharp.fluid}
          />
          New main drain lines installed alongside weeping tiles in a custom
          home build.
        </p>
        <p>
          <Img
            style={{
              maxWidth: 235,
            }}
            fluid={img2.childImageSharp.fluid}
          />
          New grease trap installed in restaurant as per local plumbing codes
        </p>
        <p>
          <Img
            style={{
              maxWidth: 235,
            }}
            fluid={img3.childImageSharp.fluid}
          />
          New drain trenches for relocated counters and sinks in Restaurant.
          <br />
          <Link to="/plumbing-backflow/">View more examples</Link>
        </p>
      </Row>
    </div>
  </Layout>
)

export default Page

export const query = graphql`
  query PlumbingQuery {
    site {
      siteMetadata {
        title
      }
    }
    imgHeader: file(relativePath: { regex: "/plumbing-header.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
        original {
          width
        }
      }
    }
    img1: file(relativePath: { regex: "/plumbing/arccon-plumbing-04.png/" }) {
      ...Thumb
    }
    img2: file(relativePath: { regex: "/plumbing/arccon-plumbing-05.png/" }) {
      ...Thumb
    }
    img3: file(relativePath: { regex: "/plumbing/arccon-plumbing-06.png/" }) {
      ...Thumb
    }
  }
`
